import { styledComponent, Box } from '@hermes/web-components'

export const FooterContainer = styledComponent(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.neutral[200]}`,
  paddingTop: '55px',
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%'
}))

export const FooterColumn = styledComponent(Box)(({ theme }) => ({
  width: '20%',
  ':not(:last-child)': {
    paddingRight: '32px'
  },
  [theme.breakpoints.down('sm')]: {
    width: '50%',
    paddingRight: 0
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    marginBottom: '24px'
  },
  backgroundColor: '#ffffff'
}))

export const PaymentMethods = styledComponent(Box)({
  display: 'inline-flex',
  marginTop: '16px'
})

export const PaymentMethod = styledComponent(Box)({
  marginRight: '8px'
})

export const SocialsContainer = styledComponent(Box)({
  display: 'inline-flex',
  gap: '8px'
})

export const FooterLinks = styledComponent(Box)(({ theme }) => ({
  'a, p': {
    cursor: 'pointer',
    color: theme.palette.neutral[900],
    fontSize: '14px',
    display: 'block',
    lineHeight: '28px',
    padding: '10px 0',
    fontWeight: 400,
    height: '100%',
    width: '100%',
    textDecoration: 'none'
  }
}))
